const titleKorean = {
    index: {
        title: 'Jiangyan Guluotang 관광 및 문화 명승지에 오신 것을 환영합니다~！',
        dynamic: "새로운 기능",
        notice: "명승지 발표",


        survey: "명승지 개요",
        surveyT: 'Jiangyan Guluotang 관광 및 문화 풍경구는 타이저우시 장옌 구의 문화 상업 중심지에 위치하고 있으며 면적은 42에이커, 총 건축 면적은 거의 30,000제곱미터이며 Jinjiangyan Tourism Development Co., Ltd.가 3억 위안을 투자하여 건설했습니다.',
        position: "지리적 위치",
        selfDriving: '자가 운전 경로',
        timetable: "버스 승객 스케줄",
        train: "열차 시간표",
        airport: "Yangtai Airport행 항공권",
        culture: "역사와 문화",
        cultureT: 'Taizhou Jiangyan Guluotang 관광 문화 풍경구 푸른 벽돌과 타일, 깊은 안뜰, 장엄한 돌 드럼, 청색 돌 포장, 집중은 Jiangyan 고대 주거지의 특성을 구현하여 사람 중심적이고 소박하며 절묘한 재료의 특성을 보여주고 아름답고 관대합니다.',
        today: "오늘날의 Guluotang",
        todayT: "연예인 문화, 체스 문화, 서예 및 그림 문화, 고대 주택 문화, 차 문화 및 상업 문화가 빛납니다. Guluotang 관광 및 문화 명승지는 Jiangyan 관광의 새로운 랜드 마크이자 문화 산업의 웅장한 전망 공원이되었습니다. 2014 년 11 월에는 국가 AAAA급 명승지로 승인되었습니다.",
        more: "더 보기",
        ren: "사람",
        qi: "체스",
        cha: "차",
        ju: "거주하다",
        yi: "예술",
        shang: "사업",


        wei: "위챗 구독",
        wang: "온라인 예약",
        jiam: "프랜차이즈 가맹점",
        xuni: "가상 투어",
        map: "경치 좋은 지도",
        hui: "맨 위로 이동",
        footer: `© Copyright 2012-2024 Jiangyan Guluotang 관광 및 문화 명승지 <br>All Rights Reserved
            IE7.0 이상 1024*768 호스트: Taizhou Jinjiangyan Tourism Development Co., Ltd<br>.님 전원 공급
            제작: Lerx 기술 지원: Jiangsu Keruida
            Su ICP 번호 19014745`,

        dyfw: "가이드 투어",
        wcsp: "문화 및 창작 제품",
        jdcy: "호텔 다이닝",
        xwzx: "소식",
        xwzxT: "여행을 용이하게 하기 위해 공식 뉴스 및 이벤트에 대한 간략한 개요를 확인하세요.",
        jqjd: "명승 지",
        jqjdT: "고대 운율 Luotang, 천년으로 돌아가는 꿈 - Jiangyan Guluotang 문화 투어",
        zjglt: "구뤄탕(Guluotang)으로 걸어 들어가세요",
        zjgltT: '역사가 있는 거리인 Jiangyan Guluotang 탐험',
        jqmt: "경치 좋은 사진",
        jqmtT: "Guluotang의 매력, Jiangyan Scenic Area의 시각적 향연",
        yqlj: "링크",
        yqljT: "Guluotang과 손을 잡으십시오: Jiangyan의 아름다움을 감상하고 우정과 협력을 연결하십시오.",


    }
}
export default titleKorean;
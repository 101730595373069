import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  // 官网
  {
    path: '/',
    name: 'officialWebsite',
    component: () => import("@/views/officialWebsite/index/index.vue"),
    meta: { title: "姜堰古罗塘旅游文化景区" },
  },
  {
    path: "/listPage",
    name: "listPage",
    component: () => import("@/views/officialWebsite/listPage/index.vue"),
    meta: { title: "姜堰古罗塘旅游文化景区" },
  },
  {
    path: '/comprehensiveDettail',
    name: 'comprehensiveDettail',
    component: () => import("@/views/officialWebsite/comprehensive/dettail.vue"),
    meta: { title: "姜堰古罗塘旅游文化景区" },
  },
  {
    path: '/comprehensive',
    name: 'comprehensive',
    component: () => import("@/views/officialWebsite/comprehensive/index.vue"),
    meta: { title: "姜堰古罗塘旅游文化景区" },
  },
  // 官网end


]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})



router.beforeEach((to, from, next) => {//beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {//判断是否有标题
    document.title = to.meta.title
  } else {
    document.title = '古罗塘景区综合管控平台'
  }
  if (!to.matched.length) {
    console.log('The route does not exist.');
    // 重定向到首页或者其他合法的路由
    next({ name: 'officialWebsite' });
  } else {
    next();
  }
  next()  //执行进入路由，如果不写就不会进入目标页
})
export default router

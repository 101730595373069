const titleJapanese = {
    index: {
        title: '江岩グルオタン観光と文化景勝地へようこそ~！',
        dynamic: "新機能",
        notice: "景勝地発表",
        survey: "景勝地の概要",
        surveyT: '江延溝堂観光文化風景区は、泰州市江岩区の文化と商業の中心地に位置し、42エーカーの面積をカバーし、総建築面積は約30,000平方メートルで、3億元の投資で晋江岩観光開発有限公司によって建設されています。',
        position: "地理的な場所",
        selfDriving: 'セルフドライブルート',
        timetable: "バスの乗客のスケジュール",
        train: "列車の時刻表",
        airport: "陽台空港行きの航空券",
        culture: "歴史と文化",
        cultureT: '台州江州江岩グルオタン観光文化風景区の青いレンガとタイル、深い中庭、雄大な石の太鼓、ブルーストーンの舗装は、江岩の古代の住居の特徴を体現し、人々志向、気取らない、絶妙な素材、美しく寛大な特性を示しています。',
        today: "今日のグルオタン",
        todayT: "有名人の文化、チェス文化、書道と絵画の文化、古代の家屋文化、茶文化、商業文化が輝いています。 グルオタン観光文化景勝地は、江湾観光の新たなランドマークとなり、文化産業の壮大な景観公園になりました。 2014年11月には、全国AAAA級の景勝地に認定されました。",
        more: "続きを見る",
        ren: "人",
        qi: "チェス",
        cha: "茶",
        ju: "住む",
        yi: "芸術",
        shang: "事",


        wei: "WeChatサブスクリプション",
        wang: "オンライン予約",
        jiam: "フランチャイズ加盟店",
        xuni: "バーチャルツアー",
        map: "風光明媚な地図",
        hui: "トップに戻る",


        footer: ` © Copyright 2012-2024 江湾グルオタン観光文化景勝地 <br>All Rights Reserved
            IE7.0以上1024 * 768台州錦江岩観光開発有限公司が主催 パワー<br>ド
            Lerxテクニカルサポート:江蘇ケルイダ
            蘇ICP番号19014745`,

        dyfw: "ガイドツアー",
        wcsp: "文化的で創造的な製品",
        jdcy: "ホテルのダイニング",
        xwzx: "ニュース",
        xwzxT: "公式ニュースやイベントの概要をすばやく入手して、旅行を円滑に進めましょう",
        jqjd: "景勝地",
        jqjdT: "古代の韻羅堂、千年紀に戻って夢見る - 江湾グルオタン文化ツアー",
        zjglt: "グルオタンに足を踏み入れる",
        zjgltT: '歴史のある通り、江岩グルオタンを探索する',
        jqmt: "風光明媚な写真",
        jqmtT: "グルオタンの魅力、江湾風景区の視覚的な饗宴",
        yqlj: "リンクス",
        yqljT: "グルオタンと手をつないで:江湾の美しさを鑑賞し、友情と協力を結びつける",

    }
}
export default titleJapanese;
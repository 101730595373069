const en = {
    index: {
        title: 'Welcome to Jiangyan Guluotang Tourist and Cultural Scenic Area~！',
        dynamic: "Latest News",
        notice: "Scenic Area Announcement",
        survey: "Overview of the scenic spot",
        surveyT: 'Jiangyan Guluotang Tourism and Cultural Scenic Area is located in the cultural and commercial center of Jiangyan District, Taizhou City, covering an area of 42 acres, with a total construction area of nearly 30,000 square meters, and is built by Jinjiangyan Tourism Development Co., Ltd. with an investment of 300 million yuan.',
        position: "geographical location",
        selfDriving: 'Self-drive routes',
        timetable: "Bus passenger schedules",
        train: "Train schedules",
        airport: "Flights to Yangtai Airport",
        culture: "History and culture",
        cultureT: 'Taizhou Jiangyan Guluotang Tourism and Cultural Scenic Area blue bricks and tiles, deep courtyards, majestic stone drums, bluestone paving, concentrated embodies the characteristics of Jiangyan ancient dwellings, showing the characteristics of people-oriented, unpretentious, exquisite materials, beautiful and generous.',
        today: "Guluotang today",
        todayT: "Celebrity culture, chess culture, calligraphy and painting culture, ancient house culture, tea culture, and commercial culture shine. Guluotang Tourism and Cultural Scenic Spot has become a new landmark of Jiangyan tourism and a grand view park of cultural industry. In November 2014, it was approved as a national AAAA-level scenic spot.",
        more: "See more",
        ren: "person",
        qi: "chess",
        cha: "Tea",
        ju: "reside",
        yi: "art",
        shang: "business",


        wei: "WeChat subscription",
        wang: "Book online",
        jiam: "Franchise merchants",
        xuni: "Virtual tours",
        map: "Scenic map",
        hui: "Back to top",
        footer: `© Copyright 2012-2024 Jiangyan Guluotang Tourism and Cultural Scenic Spot <br>All Rights Reserved
        IE7.0 or above 1024*768 Hosted by Taizhou Jinjiangyan Tourism Development Co., Ltd<br>. Powered
        by Lerx Technical Support: Jiangsu Keruida
        Su ICP No. 19014745`,

        dyfw: "Guided tours",
        wcsp: "Cultural and creative products",
        jdcy: "Hotel dining",
        xwzx: "News",
        xwzxT: "Get a quick overview of official news and events to facilitate your travels",
        jqjd: "Scenic spots",
        jqjdT: "Ancient rhyme Luotang, dreaming back to the millennium - Jiangyan Guluotang Cultural Tour",
        zjglt: "Walk into Guluotang",
        zjgltT: 'Explore Jiangyan Guluotang, a street with history',
        jqmt: "Scenic pictures",
        jqmtT: "Guluotang charm, the visual feast of Jiangyan Scenic Area",
        yqlj: "Links",
        yqljT: "Hand in hand with Guluotang: appreciate the beauty of Jiangyan and link friendship and cooperation",

    }
}
export default en;